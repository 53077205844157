<template>
  <div class="cadastro-usuario">
    <v-container fluid>
      <h1 class="titulo" v-translate>Usuários web</h1>
      <div class="box-table">
        <v-skeleton-loader
            :loading="loadingTable"
            :transition="'fade-transition'"
            height="500"
            type="table">
          <v-data-table
            :headers="headers"
            :items="listaItens"
            :search="search"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"
            @click:row="opemModalDadosUsuario"> 

            <template v-slot:top>
              <BrToolBar
                @search="buscar"
                :configFilter="{
                    listaSelect: listaKeys,
                    getItens,
                    jsonData: true,
                    switchStatus,
                    activeSwitchStatus: true,
                    isStatus,
                    switchStatusName: 'Desativados'
                  }"
                :addlFilter="false"
                :labelTextField="'Busca por Telefone:'" />
            </template>
            
            <!-- <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.cod }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.cpf | document }}</td>
                  <td>{{ item.date }}</td>
                  <td>
                    <v-btn
                      tile
                      outlined 
                      :color="variables.colorPrimary"
                      @click="deleteUsuario(item)"
                      v-if="permiteAcao($route, 'delete')">
                      {{ item.status === 'A' ? 'Ativo' : 'Inativo' }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template> -->

            <template v-slot:item.jsonData.phone="{ item }">
              {{ `${item.jsonData.dddPhone}${item.jsonData.phone}` | phone }}
            </template>

            <template v-slot:item.created="{ item }">
              {{ item.created | datas }}
            </template>

            <template v-slot:item.status="{ item }">
              <v-chip :color="getColor(item.deleted)" dark>{{ statusUsuario(item.deleted) }}</v-chip>
            </template>

            <template v-slot:item.balanceAcc="{ item }">
              {{ item.balanceAcc | currency }}
            </template>

            <!-- <template v-slot:item.balancePrizes="{ item }">
              {{ item.balancePrizes | currency }}
            </template> -->

            <template v-slot:item.actions="{ item }">
              <v-row class="justify-end align-center">
                <v-col cols="4" class="pa-0">
                  <v-container class="pa-0">
                    <template v-if="enabledPix.buyCredits.includes(item.id)">
                      <v-btn
                        block
                        tile
                        outlined 
                        :color="getColor(item.deleted, true)"
                        @click.stop="desabilitarPix(item)"
                        v-if="permiteAcao($route, 'delete')"
                        :loading="loadingBtns"
                      >
                        Desabilitar Pix
                      </v-btn>
                    </template>

                    <template v-else>
                      <v-btn
                        block
                        tile
                        outlined 
                        :color="variables.colorPrimary"
                        @click.stop="habilitarPix(item)"
                        v-if="permiteAcao($route, 'delete')"
                        :loading="loadingBtns"
                      >
                        Habilitar Pix
                      </v-btn>
                    </template>
      
                    <v-btn
                      class="mt-1"
                      block
                      tile
                      outlined 
                      :color="variables.colorPrimary"
                      @click.stop="resetPassword(item)"
                      v-if="permiteAcao($route, 'delete')"
                      :loading="loadingBtns"
                    >
                      Resetar Senha
                    </v-btn>
                  </v-container>
                </v-col>

                <v-col cols="5" class="pa-0">
                  <v-btn
                    tile
                    outlined 
                    :color="getColor(item.deleted, true)"
                    @click.stop="deleteUsuario(item)"
                    v-if="permiteAcao($route, 'delete')">
                    {{ acaoBtn(item.deleted)  }}
                  </v-btn>
    
                  <v-btn
                    class="ml-2"
                    tile
                    outlined 
                    :color="variables.colorPrimary"
                    @click="opemModalDadosUsuario(item)"
                    v-if="permiteAcao($route, 'delete')">
                    Editar
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="10"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Events from '@/core/service/events'
import moment from 'moment'
import { errorSnackbar  } from '@/core/service/utils'

/*
* Mixins
*/
import listagemTables from '@/views/mixins/listagemTables'

export default {
  name: 'UsuarioWeb',
  mixins: [listagemTables],
  components: {
    BrToolBar: () => import('./components/toolbar')
  },
  data: () => ({
    search: '',
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Nome', value: 'jsonData.fullName' },
      // { align: 'start', class: 'table-header', text: 'COD', value: 'jsonData.cod' },
      { align: 'start', class: 'table-header', text: 'Email', value: 'email' },
      { align: 'start', class: 'table-header', text: 'Telefone', value: 'jsonData.phone' },
      { align: 'end', class: 'table-header', text: 'Total de créditos', value: 'balanceAcc' },
      // { align: 'start', class: 'table-header', text: 'Total de Prêmios', value: 'balancePrizes' },
      { align: 'start', class: 'table-header', text: 'Data', value: 'created' },
      { align: 'start', class: 'table-header', text: 'Status', value: 'status' },
      { align: 'end', class: 'table-header', text: '', value: 'actions' },
    ],
    loadingBtns: false
  }),
  computed: {
    ...mapGetters('usuariosWeb', {
      listaItens: 'listaItens',
      totalItens: 'totalItens',
      isStatus: 'isStatus',
      enabledPix: 'enabledPix',
    }),
    ...mapGetters('roles', ['permiteAcao']),

    listaKeys () {
      return {
        cpf:  '',
        name:  '',
        birth:  '',
        phone:  '',
        influencer:  '',
        bank: '',
        agency: '',
        account: '',
        digit: '',
        accountType: '',
        email:  '',
      }
    }
  },

  methods: {
    ...mapActions('usuariosWeb', ['getItens', 'editarItem', 'deletarItem', 'clearItens', 'getItem', 'switchStatus', 'editSaveItem', 'getEnabledPix']),

    buscar(val) {
      this.loadingPag = true
      this.objCampoBusca['_filter'] = { 'Entities:cod_ilike': `%${val.replace(/\D/g, '')}%` }
      this
        .getItens({ _filter: { 'Entities:cod_ilike': `%${val.replace(/\D/g, '')}%` } })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => { this.loadingPag = false })
    },

    statusUsuario (date) {
      return this.situacaoUsuario(date) ? 'Ativo' : 'Inativo'
    },

    acaoBtn (date) {
      return this.situacaoUsuario(date) ? 'Desativar' : 'Ativar'
    },

    getColor (date, inverso = false) {
      return (inverso ? !this.situacaoUsuario(date) : this.situacaoUsuario(date))  ? this.variables.colorSecondary : this.variables.colorError
    },

    situacaoUsuario (date) {
      return moment(date, 'YYYY-MM-DD').isSame(moment('1970-01-01'), 'year') ? !!1 : !!0
    },

    init() {
      this.getEnabledPix()
      this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao,  _addlFilter: this.filter ? this.filter : undefined })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => this.loadingTable = false)
    },

    resetPassword (user) {
      const lista = "0123456789BCDFGHJKLMNPQRSTVWXYZ".split('')
        const customEpoch = moment('2018-01-01 00:00:00').format('x')
        const now = moment().format('x')
        let t_diferenca = (now - customEpoch)/1000
        let retorno = ""
        while(t_diferenca > lista.length - 1) {
            let resto = t_diferenca % lista.length
            t_diferenca = (t_diferenca - resto) / lista.length
            retorno += lista[parseInt(resto)]
        }
        retorno += lista[parseInt(t_diferenca)]

        const senha = retorno.split('').reverse().join('')

      this.loadingBtns = true
      this.editSaveItem({ id: user.id,  password: senha, typeEdit: 'reset-password' })
        .then(() => {
          this.$swal({
            icon: 'success',
            text: `Senha resetada com sucesso, nova senha: ${senha}`,
          })
        })
        .finally(() => this.loadingBtns = false)
    },

    habilitarPix (user) {
      this.loadingBtns = true
      this.editSaveItem({ id: user.id, typeEdit: 'enable-pix' })
        .then(() => {
          this.$swal({
            icon: 'success',
            text: 'Pix liberado com sucesso!',
          })
        })
        .finally(() => {
          this.loadingBtns = false
          this.init()
        })
    },

    desabilitarPix (user) {
      this.loadingBtns = true
      this.editSaveItem({ id: user.id, typeEdit: 'disable-pix' })
        .then(() => {
          this.$swal({
            icon: 'success',
            text: 'Pix desabilitado com sucesso!'
          })
        })
        .finally(() => {
          this.loadingBtns = false
          this.init()
        })
    },

    deleteUsuario (item) {
      let isStatus = item.status === 'A' ? 'deactivate' : 'activate'
      this.$swal({
        icon: 'warning',
        text: `Tem certeza que deseja alterar o status de ${item.jsonData.name}`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
      .then((result) => {
        if (result.value) {
          this.deletarItem({ id: parseInt(item.id), status: isStatus }).then(() => {
            this.loadingPag = true
            this.getItens().finally(() => this.loadingPag = false)
            const msg = {
              toggle: true,
              type: 'success',
              msg: 'Alteração realizada com sucesso!'
            }
            Events.$emit('snackbarCadastro::msg', msg)
          }).catch(err => {
            const msg = {
              toggle: true,
              type: 'error',
              msg: err.error
            }
            Events.$emit('snackbarCadastro::msg', msg)
          })
        }
      })
    },

    opemModalDadosUsuario (e) {
      Events.$emit('cadastro::openModalCadastro', { id: e.id })
    }
  }
}
</script>

<style lang="scss" src="@/assets/styles/view/usuarios-web.scss"></style>

